import React from "react";
import { graphql } from 'gatsby';
// import { Link } from "gatsby";
// const utils = require('../utilities/utils');


/**
 * Home
 * @param {object} data GraphQL data
 */
const TestMarkdown = ({data}) => {
  // const md = data.testMdQuery;
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
  <section>
    <h3>{frontmatter.title}</h3>
    <p>url: {frontmatter.url}</p>
    <p>{frontmatter.pubDate}</p>
    <div dangerouslySetInnerHTML={{__html: html}} />
  </section>
)}

export default TestMarkdown;

// (frontmatter: {title: {}, slug: {}})
export const mdQuery = graphql`
  query testMdQuery {
    markdownRemark {
      frontmatter {
        title
        pubDate(formatString: "MMMM D, YYYY")
        url
      }
      html
    }
  }
` 
// export const homepageQuery = graphql`
//   query latestEpisodesQuery {
//     allEpisodesCombinedJson(sort: {order: DESC, fields: pubDate}, limit: 15) {
//       edges {
//         node {
//           title
//           pubDate(formatString: "MMMM D, YYYY")
//           showId
//           summary
//           enclosure {
//             url
//           }
//         }
//       }
//     }
//   }
// ` 
